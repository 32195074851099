






























import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import DeviceOption from '@/components/DeviceOption.vue'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import { Getter } from 'vuex-class'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { Shift } from '@/services/Shift'

@Component({
  components: {
    DeviceOption,
    BackDashboardButton
  }
})
export default class PolicyList extends Vue {
  @Getter('policy/policies') private policies!: SwitchPolicyModel[]

  get filteredPolicies() {
    return this.policies.filter((policy) => !policy._policy.sfCustom.scrubbed)
  }

  async goToApplication() {
    const canAddDevice = await Shift.canAddAdditionalDevice()
    if (canAddDevice) {
      await this.$router.push({
        name: constants.routeNames.BEFORE_APPLICATION
      })
    } else {
      const msg = this.$t('common.error.deviceLimitReached').toString()
      this.$showErrorPopUp(msg)
    }
  }

  canViewPolicyDetails(policy: SwitchPolicyModel): boolean {
    return policy._policy.status === 'IN_FORCE' ||
           policy._policy.status === 'EXPIRING' ||
           policy._policy.status === 'EXPIRED' ||
           policy._policy.status === 'LAPSING' ||
           policy._policy.status === 'LAPSED' ||
           policy._policy.status === 'AUTO_LAPSED'
  }
}
