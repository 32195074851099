



































































































import { Component, Vue } from 'vue-property-decorator'
import DeviceIcon from '@/components/DeviceIcon.vue'
import constants from '@/constants'
import DetailField from '@/components/DetailField.vue'
import BackButton from '@/components/BackButton.vue'
import AonButton from '@/components/AonButton.vue'
import { Getter } from 'vuex-class'
import {
  FnGetPolicyById
} from '@/store/modules/policy/getters'
import { doutformat } from '@/services/functions'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { PhotoModel } from '@/store/api/models/PhotoModel'
import ButtonField from '@/components/layout/ButtonField.vue'
import translate from '@/plugins/i18n'

@Component({
  components: {
    DeviceIcon,
    DetailField,
    AonButton,
    BackButton,
    ButtonField
  }
})
export default class PolicyDetail extends Vue {
  @Getter('policy/getById') private getPolicyById!: FnGetPolicyById
  @Getter('policy/photos') private photos!: PhotoModel
  @Getter('app/disablePlanChange') disablePlanChange!: boolean
  @Getter('app/deactivateCancelCoverage') deactivateCancelCoverage!: boolean

  private policy: SwitchPolicyModel | undefined

  private loaded = false

  ROUTE_NAMES = constants.routeNames

  getPhoto(photo: string): string {
    return this.photos[photo].base64
  }

  getPhotoName(photo: string): string {
    return this.photos[photo].name
  }

  viewImage(name: string, url: string) {
    this.$viewImage(name, url)
  }

  isSwitch(deviceType: string) {
    return deviceType !== 'switchlite'
  }

  destroyed() {
    if (this.photos) {
      URL.revokeObjectURL(this.getPhoto('front'))
      URL.revokeObjectURL(this.getPhoto('back'))
    }
  }

  formatDate(date: string | undefined) {
    if (date) {
      return doutformat(date)
    } else {
      return ''
    }
  }

  formatPrice(value: string | number) {
    if (value) {
      return translate.t('common.currencySymbol', { amount: Number(value).toLocaleString() }).toString()
    } else {
      return ''
    }
  }

  mounted() {
    const id = this.$route.params.id
    if (id) {
      this.policy = this.getPolicyById(id)
      this.loaded = true
    }
  }

  viewPolicyCertificate() {
    this.$router.push({ name: constants.routeNames.POLICY_CERTIFICATE })
  }

  cancelCoverage() {
    this.$router.push({ name: constants.routeNames.CANCEL_COVERAGE })
  }

  changePlanType() {
    this.$router.push({ name: constants.routeNames.CHANGE_PLAN })
  }
}
