








import constants from '@/constants'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PolicyIndex extends Vue {
  transitionName = constants.transitionNames.SLIDE_LEFT

  created () {
    this.$router.beforeEach((to, from, next) => {
      if (from.meta && 'pageIndex' in from.meta && to.meta && 'pageIndex' in to.meta) {
        this.transitionName = to.meta.pageIndex > from.meta.pageIndex ? constants.transitionNames.SLIDE_LEFT : constants.transitionNames.SLIDE_RIGHT
      } else {
        this.transitionName = constants.transitionNames.FADE
      }
      next()
    })
  }
}
