
































































import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import BackButton from '@/components/BackButton.vue'
import FluidCheckbox from '@/components/facts/FluidCheckbox.vue'
import ImportantNote from '@/components/ImportantNote.vue'
import AonButton from '@/components/AonButton.vue'
import moment from 'moment'
import { DATE_OUTPUT_FORMAT, doutformat } from '@/services/functions'
import { Shift } from '@/services/Shift'
import { Getter } from 'vuex-class'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { shift } from '@uncharted/coverhub-framework'

@Component({
  components: {
    BackButton,
    AonButton,
    ImportantNote,
    FluidCheckbox
  }
})
export default class ChangePlan extends Vue {
  @Getter('policy/policies') private policies!: SwitchPolicyModel[]

  private policyId = ''

  private buttonLabelNewPlan = ''

  // Customer consent to changing a plan
  private accepted = false

  private newPlanType = ''

  // View model for current plan
  private oldPlan = {
    planType: '',
    policyNumber: '',
    warrantyStartDate: '',
    warrantyEndDate: ''
  }

  // View model for new plan
  private newPlan = {
    selectedPlan: '',
    effectiveDate: '',
    fee: ''
  }

  created() {
    this.policyId = this.$route.params.id as string
    const switchPolicyModel = this.policies.find((p) => p.policyId === this.policyId)
    if (switchPolicyModel) {
      const session = switchPolicyModel._product.productEngine
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const fact = session.describeFacts().components[0].facts.find(f => f.id === 'COMPONENT:base.plan.plan')!
      const currentPlan = fact.currentValue
      const policyDesc = session.describePolicy()
      const warrantyStartDate = policyDesc.policyStartDate
      const warrantyEndDate = policyDesc.policyEndDate

      this.oldPlan.planType = this.$t(`changePlan.mapping.${currentPlan}`).toString()
      this.oldPlan.policyNumber = switchPolicyModel._policy.policyNumber
      this.oldPlan.warrantyStartDate = doutformat(warrantyStartDate)
      this.oldPlan.warrantyEndDate = doutformat(warrantyEndDate)

      const newPlan = currentPlan === 'monthly' ? 'yearly' : 'monthly'
      this.newPlanType = newPlan
      const newSession = shift.productengine.newSessionFromConfig(switchPolicyModel._product.productDefinition, session.serializeConfig())
      newSession.setPricingType('EXTENSION')
      newSession.setFactValue('COMPONENT:base.plan.plan', newPlan)

      this.newPlan.selectedPlan = this.$t(`changePlan.mapping.${newPlan}`).toString()
      this.newPlan.effectiveDate = moment(warrantyEndDate).add(1, 'day').format(DATE_OUTPUT_FORMAT)
      this.newPlan.fee = this.$t(`changePlan.fee.${newPlan}`).toString()
      this.buttonLabelNewPlan = this.$t(`changePlan.button.${newPlan}`).toString()
    } else {
      throw Error(`Could not find policy ${this.policyId}`)
    }
  }

  changePlan() {
    Shift.changePlanType(this.policyId)
      .then((response) => {
        if (!response.validationErrors || response.validationErrors.length === 0) {
          this.$router.replace({ name: constants.routeNames.CHANGE_PLAN_SUCCESS })
        } else {
          this.$router.replace({ name: constants.routeNames.CHANGE_PLAN_FAILURE })
        }
      })
  }
}
