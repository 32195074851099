
















































import { PolicyCertificateModel } from '@/store/api/models/PolicyCertificateModel'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import ImportantNote from '@/components/ImportantNote.vue'
import BackButton from '@/components/BackButton.vue'
import AonButton from '@/components/AonButton.vue'
import { Shift } from '@/services/Shift'

@Component({
  components: {
    ImportantNote,
    AonButton,
    BackButton
  }
})
export default class PolicyCertificate extends Vue {
  @Getter('policy/policyCertificate')
  private policyCertificate!: PolicyCertificateModel

  private loaded = false

  private fromCompleted = false

  private pdfUrl = ''

  private isAndroid = navigator.userAgent.indexOf('Android') !== -1

  private warranty = {
    policyNumber: '',
    serialNumber: '',
    productionNumberJoyConLeft: '',
    productionNumberJoyConRight: '',
    planType: '',
    dateOfSubscription: '',
    warrantyStartDate: '',
    warrantyEndDate: ''
  }

  created() {
    this.fromCompleted = !!this.$route.query?.completed

    this.warranty = {
      policyNumber: this.policyCertificate.policyNumber,
      serialNumber: this.policyCertificate.serialNumber,
      productionNumberJoyConLeft: this.policyCertificate.productionNumberJoyConLeft,
      productionNumberJoyConRight: this.policyCertificate.productionNumberJoyConRight,
      planType: this.policyCertificate.planType,
      dateOfSubscription: this.policyCertificate.originalStartDateTime,
      warrantyStartDate: this.policyCertificate.warrantyStartDate,
      warrantyEndDate: this.policyCertificate.warrantyEndDate
    }

    const planKey = `policyCertificate.mapping.${this.warranty.planType}`
    this.warranty.planType = this.$t(planKey).toString()

    this.loaded = true
  }

  destroyed() {
    if (this.pdfUrl) {
      try {
        URL.revokeObjectURL(this.pdfUrl)
      } catch (e) {
      }
    }
  }

  private viewPdf() {
    const policyId = this.$route.params.id
    const pdfWindow = window.open()
    if (pdfWindow) {
      Shift.downloadPdfPolicyCertificate(policyId)
        .then((pdf) => {
          this.pdfUrl = URL.createObjectURL(pdf.blob)
          pdfWindow.location.href = this.pdfUrl
        })
        .catch((error) => {
          console.error(error)
          this.$showErrorPopUp(/* TODO proper error */ 'Failed to open PDF.')
        })
    } else {
      this.$showErrorPopUp(/* TODO proper error */ 'Failed to open PDF.')
    }
  }

  //  Download only on Android device, due to android preview pdf limitation
  private downloadPdf = () => {
    const policyId = this.$route.params.id
    Shift.downloadPdfPolicyCertificate(policyId)
      .then((pdf) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(pdf.blob)
        link.setAttribute('download', pdf.filename)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        link.remove()
      })
      .catch((error: any) => {
        console.error(error)
        this.$showErrorPopUp(/* TODO proper error */ 'Failed to open PDF.')
      })
  }
}
