

































































import { Component, Vue } from 'vue-property-decorator'
import BackButton from '@/components/BackButton.vue'
import FluidCheckbox from '@/components/facts/FluidCheckbox.vue'
import AonButton from '@/components/AonButton.vue'
import { Getter } from 'vuex-class'
import { CancelPlanModel } from '@/store/api/models/CancelPlanModel'
import { ACTION_POLICY_CANCEL_PLAN } from '@/store/modules/policy/actionTypes'
import { doutformat } from '@/services/functions'

@Component({
  components: {
    BackButton,
    AonButton,
    FluidCheckbox
  }
})
export default class CancelCoverage extends Vue {
  @Getter('policy/cancelPlan')
  private cancelPlan!: CancelPlanModel

  onConsent(value: boolean) {
    this.cancelPlan.consent = value
  }

  formatDate(date: string) {
    return doutformat(date)
  }

  async cancelCoverage() {
    await this.$store.dispatch(ACTION_POLICY_CANCEL_PLAN)
    await this.$router.replace({ name: this.ROUTE_NAMES.CANCEL_COVERAGE_SUCCESS })
  }
}
